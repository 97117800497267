import { useNavigate } from 'react-router';
import React, { useEffect } from 'react';
/**
 * A helpful component that performs a react-router redirect.
 */
export default function NavigateTo({ to }) {
    const navigate = useNavigate();
    useEffect(() => {
        navigate(to);
    }, []);
    return React.createElement("div", null);
}
