import jwtDecode from 'jwt-decode';
export function getProductsForRecommendations(jwt) {
    const { recommendations } = jwtDecode(jwt);
    return JSON.parse(recommendations);
}
export const accessoryList = [
    {
        id: `104882`,
        retailerSku: '5730704',
    },
    {
        id: '104878',
        retailerSku: '5730705',
    },
    {
        id: '104888',
        retailerSku: '5748618',
    },
    {
        id: '107377',
        retailerSku: '6182525',
    },
    {
        id: '110753',
        retailerSku: '6352717',
    },
    {
        id: '110748',
        retailerSku: '6361629',
    },
    {
        id: '115763',
        retailerSku: '6362972',
    },
    {
        id: '112197',
        retailerSku: '6401964',
    },
    {
        id: '112184',
        retailerSku: '6401965',
    },
    {
        id: '114417',
        retailerSku: '6425687',
    },
    {
        id: '113815',
        retailerSku: '6437954',
    },
    {
        id: '113812',
        retailerSku: '6437955',
    },
    {
        id: '114303',
        retailerSku: '6451074',
    },
    {
        id: '114296',
        retailerSku: '6470929',
    },
    {
        id: '117922',
        retailerSku: '6480673',
    },
    {
        id: '117149',
        retailerSku: '6492994',
    },
    {
        id: '114301',
        retailerSku: '6493037',
    },
    {
        id: '117869',
        retailerSku: '6500864',
    },
    {
        id: '114297',
        retailerSku: '6501155',
    },
];
