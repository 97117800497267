import { gql } from '@apollo/client';
export const PRODUCT_BY_NSUID = gql `
    query ProductByNsuidForLongArm($nsuid: String!, $locale: Locale!) {
        products: storeProducts(where: { nsuid: { match: $nsuid } }, locale: $locale) {
            locale
            name
            productType
            productImage {
                url
                resourceType
                publicId
            }
            productGallery {
                url
                resourceType
                publicId
            }
            description
            genres {
                label
            }
            platform {
                label
            }
            softwarePublisher
            playersMax
            playersMaxLocal
            playersMaxOnline
            playersMin
            playersMinLocal
            playersMinOnline
            releaseDate
            playModes {
                label
            }
            contentRating {
                label
                icon {
                    url
                }
            }
            contentDescriptors {
                label
            }
            disclaimer
            supportedLanguages
        }
    }
`;
export const PRODUCT_BY_SKU = gql `
    query ProductBySKUForLongArm($sku: String!, $locale: Locale!) {
        products: storeProducts(where: { sku: { eq: $sku } }, locale: $locale) {
            locale
            name
            productType
            productImage {
                url
                resourceType
                publicId
            }
            productGallery {
                url
                resourceType
                publicId
            }
            description
            genres {
                label
            }
            platform {
                label
            }
            softwarePublisher
            playersMax
            playersMaxLocal
            playersMaxOnline
            playersMin
            playersMinLocal
            playersMinOnline
            releaseDate
            playModes {
                label
            }
            contentRating {
                label
                icon {
                    url
                }
            }
            contentDescriptors {
                label
            }
            disclaimer
            supportedLanguages
        }
    }
`;
export const PRODUCT_IDS_FOR_CATEGORY = gql `
    query MyQuery($categoryId: String!, $retailerId: String!) {
        products: getProducts(categoryId: $categoryId, retailerId: $retailerId) {
            nsuid
            productId
        }
    }
`;
export const RETAILER_SKU = gql `
    query MyQuery($productId: String!, $retailerId: String!) {
        sku: getProducts(productId: $productId, retailerId: $retailerId) {
            retailerProducts {
                retailerProductSku
            }
        }
    }
`;
export const PRODUCT_TYPE_SIMPLE = 'SIMPLE';
export const NINTENDO_COM_VIDEO_URL_PREFIX = 'https://assets.nintendo.com/video/upload/';
