var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAuthentication } from '../context/AuthenticationContextProvider';
import { syncKiosk } from '../api/kioskControlApi';
import ErrorModal from './common/ErrorModal';
import LoadingDimmer from './common/LoadingDimmer';
function SyncKioskRoute({ children, syncType }) {
    const { accountInfo, kioskInfo, handleReleaseKiosk } = useAuthentication();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [search] = useSearchParams();
    const pathParams = useParams();
    // Set the loading var, and reset error to true on path/query change.
    // If syncKiosk attempts and fails, set error to true.
    // Set loading to false at the end, so it will be set regardless of whether the syncKiosk call was made or not.
    useEffect(() => {
        setLoading(true);
        setError(false);
        (() => __awaiter(this, void 0, void 0, function* () {
            // If there are kiosk query params, then don't attempt a sync. User is currently scanning a QR code.
            if (accountInfo &&
                kioskInfo &&
                kioskInfo.hasControl &&
                !(search.get('kiosk_id') || search.get('one_time_code'))) {
                if (!(yield syncKiosk(accountInfo.token, kioskInfo.kioskId, {
                    type: syncType,
                    id: pathParams.nsuid || pathParams.accessoryId || pathParams.categoryId,
                }, handleReleaseKiosk))) {
                    setError(true);
                }
            }
            setLoading(false);
        }))();
    }, [pathParams, search]);
    if (loading)
        return React.createElement(LoadingDimmer, null);
    if (error) {
        return React.createElement(ErrorModal, null);
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return React.createElement(React.Fragment, null, children);
}
export default SyncKioskRoute;
