var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import Question from './Question';
import QuizResults from './QuizResults';
import { quizNext, quizResults, quizSelect, quizStart } from '../../api/kioskQuizApi';
import { useAuthentication } from '../../context/AuthenticationContextProvider';
import LoadingDimmer from '../common/LoadingDimmer';
import { questionList } from './QuestionList';
import ErrorModal from '../common/ErrorModal';
/**
 * The kiosk expects indices.
 * Get the index of the correct answer in the shuffled answers.
 * Go through the non-correct answers, and get each of their indices in the shuffled answers.
 */
const getKioskAnswerOrder = (shuffledQuestion, currentQuestionIndex) => ({
    correct: shuffledQuestion.answers.findIndex((answer) => answer.id === shuffledQuestion.correctAnswerId),
    incorrect: questionList[currentQuestionIndex].answers
        .filter((answer) => answer.id !== shuffledQuestion.correctAnswerId)
        .map((answer) => shuffledQuestion.answers.findIndex((shuffledAnswer) => shuffledAnswer.id === answer.id)),
});
function Quiz() {
    const authContext = useAuthentication();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(true);
    const [correctAnswerCount, setCorrectAnswerCount] = useState(0);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [quizComplete, setQuizComplete] = useState(false);
    const [shuffledQuestion, setShuffledQuestion] = useState(null);
    const [selectedAnswerId, setSelectedAnswerId] = useState(null);
    const restartQuiz = () => {
        setLoading(true);
        setCurrentQuestionIndex(0);
        setCorrectAnswerCount(0);
        setQuizComplete(false);
        setShuffledQuestion(null);
        setSelectedAnswerId(null);
    };
    const getNextQuestion = () => {
        const nextIndex = currentQuestionIndex + 1;
        if (nextIndex >= questionList.length) {
            setQuizComplete(true);
        }
        else {
            setCurrentQuestionIndex(nextIndex);
        }
    };
    const incrementCorrectAnswerCount = () => {
        setCorrectAnswerCount(correctAnswerCount + 1);
    };
    const handleKioskCall = (kioskFunction) => {
        var _a;
        if ((_a = authContext.kioskInfo) === null || _a === void 0 ? void 0 : _a.hasControl) {
            (() => __awaiter(this, void 0, void 0, function* () {
                setLoading(true);
                if (!(yield kioskFunction())) {
                    setError(true);
                }
                setLoading(false);
            }))();
        }
    };
    const selectAnswer = (answerId) => {
        if (selectedAnswerId !== shuffledQuestion.correctAnswerId) {
            handleKioskCall(() => quizSelect(authContext.accountInfo.token, authContext.kioskInfo.kioskId, {
                answer: shuffledQuestion.answers.findIndex((answer) => answer.id === answerId),
            }, authContext.handleReleaseKiosk));
            if (answerId === shuffledQuestion.correctAnswerId) {
                if (selectedAnswerId === null) {
                    incrementCorrectAnswerCount();
                }
            }
            setSelectedAnswerId(answerId);
        }
    };
    useEffect(() => {
        // Get current question. Shuffle answers before storing in state.
        const unshuffledQuestion = questionList[currentQuestionIndex];
        const shuffledAnswers = unshuffledQuestion.answers
            .map((value) => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value);
        const currentQuestion = Object.assign(Object.assign({}, unshuffledQuestion), { answers: shuffledAnswers });
        setShuffledQuestion(currentQuestion);
        setSelectedAnswerId(null);
        setLoading(false);
        if (currentQuestionIndex === 0) {
            handleKioskCall(() => quizStart(authContext.accountInfo.token, authContext.kioskInfo.kioskId, getKioskAnswerOrder(currentQuestion, currentQuestionIndex), authContext.handleReleaseKiosk));
        }
        else {
            handleKioskCall(() => quizNext(authContext.accountInfo.token, authContext.kioskInfo.kioskId, getKioskAnswerOrder(currentQuestion, currentQuestionIndex), authContext.handleReleaseKiosk));
        }
    }, [currentQuestionIndex]);
    useEffect(() => {
        if (quizComplete) {
            handleKioskCall(() => quizResults(authContext.accountInfo.token, authContext.kioskInfo.kioskId, authContext.handleReleaseKiosk));
        }
    }, [quizComplete]);
    if (loading)
        return React.createElement(LoadingDimmer, null);
    if (error) {
        return React.createElement(ErrorModal, null);
    }
    return quizComplete ? (React.createElement(QuizResults, { correctAnswerCount: correctAnswerCount, questionCount: questionList.length, restartQuiz: restartQuiz })) : (React.createElement(Question, { quizQuestion: shuffledQuestion, selectedAnswerId: selectedAnswerId, getNextQuestion: getNextQuestion, selectAnswer: selectAnswer, questionNumber: currentQuestionIndex + 1, questionCount: questionList.length }));
}
export default Quiz;
