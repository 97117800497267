import React from 'react';
/**
 * Authentication Context related object types.
 */
export var UserType;
(function (UserType) {
    UserType["GUEST"] = "GUEST";
    UserType["NINTENDO_ACCOUNT"] = "NINTENDO_ACCOUNT";
})(UserType || (UserType = {}));
/**
 * Default Authentication Context value.
 */
export const defaultAuthenticationContextValue = {
    accountInfo: null,
    handleLogin: () => { },
    handleLogout: () => { },
    kioskInfo: null,
    handleControlKiosk: () => { },
    handleReleaseKiosk: () => Promise.resolve(),
    capturingKiosk: false,
    handlingLogin: false,
    kioskInactiveState: false,
};
export default React.createContext(defaultAuthenticationContextValue);
