import config from 'ConfigData';
const constructImageUrl = (imageFilename) => `${config.retailInteractiveConsumerWebAppBaseUrl}/category_assets/images/quiz/${imageFilename}`;
export const questionList = [
    {
        questionImage: constructImageUrl('first/question.png'),
        questionText: 'Which power-up gives you the ability to throw fireballs?',
        answered: {
            image: constructImageUrl('first/question.png'),
            header: 'You got it!',
            description: 'The Fire Flower power-up gives you the ability to throw fireballs at enemies or use to light torches in the Super Mario 3D World + Bowser’s Fury game.',
        },
        correctAnswerId: 0,
        answers: [
            { id: 0, image: constructImageUrl('first/answer_1.png'), text: 'Fire Flower' },
            { id: 1, image: constructImageUrl('first/answer_2.png'), text: 'Boomerang Flower' },
            { id: 2, image: constructImageUrl('first/answer_3.png'), text: 'Super Bell' },
            { id: 3, image: constructImageUrl('first/answer_4.png'), text: 'Double Cherry' },
        ],
    },
    {
        questionImage: constructImageUrl('second/question.png'),
        questionText: 'Who created Gooigi?',
        answered: {
            image: constructImageUrl('second/answered.png'),
            header: 'Goo-d job!',
            description: 'Professor E. Gadd discovered the green element known as Goo, then used Luigi’s biological data to create an exceedingly green copy of Luigi.',
        },
        correctAnswerId: 1,
        answers: [
            { id: 0, image: constructImageUrl('second/answer_1.png'), text: 'Luigi' },
            { id: 1, image: constructImageUrl('second/answer_2.png'), text: 'Professor E. Gadd' },
            { id: 2, image: constructImageUrl('second/answer_3.png'), text: 'King Boo' },
            { id: 3, image: constructImageUrl('second/answer_4.png'), text: 'Hellen Gravely' },
        ],
    },
    {
        questionImage: constructImageUrl('third/question.png'),
        questionText: 'What’s hidden in the Secret Flower Field?',
        answered: {
            image: constructImageUrl('third/answered.png'),
            header: 'You got it!',
            description: 'The Multi Moon appears once the giant robot flower is defeated in the Super Mario Odyssey game.',
        },
        correctAnswerId: 2,
        answers: [
            { id: 0, image: constructImageUrl('third/answer_1.png'), text: 'Rocket Flower' },
            { id: 1, image: constructImageUrl('third/answer_2.png'), text: 'Coins' },
            { id: 2, image: constructImageUrl('third/answer_3.png'), text: 'Multi Moon' },
            { id: 3, image: constructImageUrl('third/answer_4.png'), text: 'Seed' },
        ],
    },
    {
        questionImage: constructImageUrl('fourth/question.png'),
        questionText: 'Which compatible amiibo figure would you scan to get a Life-Up Heart in the Super Mario Odyssey game?',
        answered: {
            image: constructImageUrl('fourth/question.png'),
            header: 'Peachy keen, jellybean!',
            description: 'You can scan a Peach amiibo figure to get a Life-Up Heart. Thanks, Peach!',
        },
        correctAnswerId: 3,
        answers: [
            { id: 0, image: constructImageUrl('fourth/answer_1.png'), text: 'Mario' },
            { id: 1, image: constructImageUrl('fourth/answer_2.png'), text: 'Isabelle' },
            { id: 2, image: constructImageUrl('fourth/answer_3.png'), text: 'Bowser' },
            { id: 3, image: constructImageUrl('fourth/answer_4.png'), text: 'Peach' },
        ],
    },
    {
        questionImage: constructImageUrl('fifth/question.png'),
        questionText: 'Which heart do you throw to make allies in the Kirby Star Allies game?',
        answered: {
            image: constructImageUrl('fifth/question.png'),
            header: 'You got it!',
            description: 'Only a Friend Heart can make allies for Kirby. So sweet!',
        },
        correctAnswerId: 3,
        answers: [
            { id: 0, image: constructImageUrl('fifth/answer_1.png'), text: 'Friendship Heart' },
            { id: 1, image: constructImageUrl('fifth/answer_2.png'), text: 'Jamba Heart' },
            { id: 2, image: constructImageUrl('fifth/answer_3.png'), text: 'Power-Up Heart' },
            { id: 3, image: constructImageUrl('fifth/answer_4.png'), text: 'Friend Heart' },
        ],
    },
    {
        questionImage: constructImageUrl('sixth/question.png'),
        questionText: 'Which of these items can Link receive from Beedle’s Shop in the Skyloft Bazaar?',
        answered: {
            image: constructImageUrl('sixth/answered.png'),
            header: 'You got it!',
            description: 'You can use the Bug Net to catch bugs and other small things. When you’re hunting bugs, it’s best to approach quietly and strike quickly!',
        },
        correctAnswerId: 0,
        answers: [
            { id: 0, image: constructImageUrl('sixth/answer_1.png'), text: 'Bug Net' },
            { id: 1, image: constructImageUrl('sixth/answer_2.png'), text: "Goddess's Harp" },
            { id: 2, image: constructImageUrl('sixth/answer_3.png'), text: 'Rupees' },
            { id: 3, image: constructImageUrl('sixth/answer_4.png'), text: 'Slingshot' },
        ],
    },
    {
        questionImage: constructImageUrl('seventh/question.png'),
        questionText: 'These beauties come in groups of three—and make Toadette smile with glee!',
        answered: {
            image: constructImageUrl('seventh/question.png'),
            header: 'Good eye! Your skills are dazzling.',
            description: 'You can try to collect these little treasures on each stage in the game.',
        },
        correctAnswerId: 1,
        answers: [
            { id: 0, image: constructImageUrl('seventh/answer_1.png'), text: 'Pluck Patch' },
            { id: 1, image: constructImageUrl('seventh/answer_2.png'), text: 'Super Gem' },
            { id: 2, image: constructImageUrl('seventh/answer_3.png'), text: 'Turnip' },
            { id: 3, image: constructImageUrl('seventh/answer_4.png'), text: 'Mole' },
        ],
    },
    {
        questionImage: constructImageUrl('eighth/question.png'),
        questionText: 'These things are real blockheads. But they can do serious damage!',
        answered: {
            image: constructImageUrl('eighth/question.png'),
            header: 'Yes!',
            description: 'Toadette can’t attack a Stumper, but she can walk on top of it.',
        },
        correctAnswerId: 3,
        answers: [
            { id: 0, image: constructImageUrl('eighth/answer_1.png'), text: 'Flaptor' },
            { id: 1, image: constructImageUrl('eighth/answer_2.png'), text: 'Mummy Me' },
            { id: 2, image: constructImageUrl('eighth/answer_3.png'), text: 'Wingo' },
            { id: 3, image: constructImageUrl('eighth/answer_4.png'), text: 'Stumper' },
        ],
    },
];
export const quizResults = {
    selectiveMemory: {
        image: constructImageUrl(`results/selective_memory.png`),
        overlayText: 'This was a really tough trivia quiz, huh?',
        retryText: 'Don’t worry, no one gets all the questions right on the first try. (I mean, some people do, but they’re few and far between.) You can always try to brush up on these games from the Nintendo Select collection—and then you can take the quiz again!',
    },
    playerWithPotential: {
        image: constructImageUrl('results/player_with_potential.png'),
        overlayText: `Whoa. That was a really tough quiz!`,
        retryText: 'You got a lot of those questions right. Pretty awesome, if you ask me. But there’s always room for improvement, so you can always re-play the games from the Nintendo Selects collection whose question you may have missed.',
    },
    smartyPants: {
        image: constructImageUrl('results/smarty_pants.png'),
        overlayText: 'Is there a video game you haven’t played?',
        retryText: 'You are a trivia whiz kid—and not just because you got a ton of answers right. Wait, that IS why you’re a trivia whiz kid! Either way, you really seem to know your stuff when it comes to games from the Nintendo Selects collection. Keep up the good work!',
    },
    quizMaster: {
        image: constructImageUrl('results/quiz_master.png'),
        overlayText: 'Is there a video game you haven’t played?',
        retryText: 'You are a trivia whiz kid—and not just because you got a ton of answers right. Wait, that IS why you’re a trivia whiz kid! Either way, you really seem to know your stuff when it comes to games from the Nintendo Selects collection. Keep up the good work!',
    },
};
