var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../../../assets/css/components/BreadCrumbs.scss';
import { useParams } from 'react-router';
import localization from '../../locale/localization';
import { useCategories } from '../../context/CategoriesContextProvider';
/**
 * Component for a generic breadcrumb link to lead a user back to a previous screen
 */
function BreadCrumbs({ showSelectedCategory }) {
    const params = useParams();
    const categoriesContext = useCategories();
    const [parentCategory, setParentCategory] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const selectedCategoryId = params.categoryId;
    useEffect(() => {
        if (categoriesContext.categories && selectedCategoryId) {
            (() => __awaiter(this, void 0, void 0, function* () {
                const retrievedParentCategory = categoriesContext.getParentCategoryForChildCategoryId(selectedCategoryId);
                setParentCategory(retrievedParentCategory);
                setSelectedCategory(categoriesContext.getCategoryForId(selectedCategoryId));
            }))();
        }
    }, [selectedCategoryId, categoriesContext]);
    return (React.createElement("ul", { className: "breadcrumbs mt-2" }, [
        { path: '/categories', text: localization.navigation.home },
        parentCategory
            ? {
                path: `/categories/${parentCategory.id}`,
                text: parentCategory.name,
            }
            : undefined,
        selectedCategory && showSelectedCategory
            ? {
                path: `/categories/${selectedCategory.id}/products`,
                text: selectedCategory.name,
            }
            : undefined,
    ]
        .filter(Boolean)
        .map((breadCrumb) => (React.createElement("li", { key: breadCrumb.text },
        React.createElement(Link, { to: breadCrumb.path },
            React.createElement("span", { className: "ml-2" }, breadCrumb.text)))))));
}
export default BreadCrumbs;
