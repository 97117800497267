import React from 'react';
import Modal from 'react-modal';
import localization from '../../locale/localization';
import '../../../assets/css/components/ErrorModal.scss';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};
function ErrorModal() {
    return (React.createElement(Modal, { isOpen: true, style: customStyles },
        React.createElement("div", { className: "text-center" }, "Error loading content."),
        React.createElement("button", { type: "button", className: "retry-button", onClick: () => {
                window.location.reload();
            } }, localization.navigation.retry)));
}
export default ErrorModal;
