import React from 'react';
import { ApolloClient, ApolloLink, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { HashRouter } from 'react-router-dom';
import axios from 'axios';
import config from 'ConfigData';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import Header from './components/header/Header';
import '../assets/css/components/LongarmApp.scss';
import LongarmMain from './components/navigation/LongarmMain';
import { AuthenticationContextProvider } from './context/AuthenticationContextProvider';
import { CategoriesContextProvider } from './context/CategoriesContextProvider';
import InactivityModal from './components/common/InactivityModal';
axios.defaults.baseURL = config.retailInteractiveServiceBaseUrl;
axios.defaults.validateStatus = (status) => status >= 200 && status <= 400;
const nintendoComGraph = new HttpLink({
    uri: config.nintendoComGraphUrl,
});
const longarmGraph = new HttpLink({
    uri: config.productCatalogGraphUrl,
});
const graphClient = new ApolloClient({
    link: ApolloLink.split((operation) => operation.getContext().clientName === 'NintendoGraph', nintendoComGraph, longarmGraph),
    cache: new InMemoryCache(),
});
/**
 * Entry point for application.
 * This is what the index should load.
 */
function LongarmApp() {
    dayjs.extend(advancedFormat);
    return (React.createElement("div", { className: "app-container" },
        React.createElement(HashRouter, null,
            React.createElement(ApolloProvider, { client: graphClient },
                React.createElement(AuthenticationContextProvider, null,
                    React.createElement(CategoriesContextProvider, null,
                        React.createElement(Header, null),
                        React.createElement(InactivityModal, null),
                        React.createElement(LongarmMain, null)))))));
}
export default LongarmApp;
