var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuthentication } from '../../../context/AuthenticationContextProvider';
import { triggerOnClick } from '../../../util/KioskUtil';
import '../../../../assets/css/components/VideosTab.scss';
import LoadingDimmer from '../../common/LoadingDimmer';
import { NINTENDO_COM_VIDEO_URL_PREFIX } from '../../../api/productGraph';
/**
 * This follows the Kiosk logic.
 * To create the poster image url, this will:
 * Replace the final `/some_product_text' with `/posters/some_product_text'
 * Replace '/video' with '/image'
 *
 * If this URL returns an error(some products don't have posters)
 * then instead just add '.png' to the end of the initial URL.
 * This will result in a frame from the middle of the video.
 *
 * @param videoUrl - URL of the video sent to the video tag
 */
const getVideoPosterImageUrl = (videoUrl) => __awaiter(void 0, void 0, void 0, function* () {
    const lastSlashIndex = videoUrl.lastIndexOf('/');
    let posterUrl = videoUrl
        .substring(0, lastSlashIndex)
        .concat('/posters')
        .concat(videoUrl.substring(lastSlashIndex))
        .replace('/video/', '/image/');
    yield axios.get(posterUrl).catch(() => {
        posterUrl = videoUrl.concat('.png');
    });
    return posterUrl;
});
function VideosTab({ videoProductGallery }) {
    const authContext = useAuthentication();
    const [videos, setVideos] = useState(null);
    // On load, each video needs to call an async function to determine what it's poster URL should be.
    // Show a loading spinner until this is complete.
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            setVideos(yield Promise.all(videoProductGallery.map((gallery) => __awaiter(this, void 0, void 0, function* () {
                // For videos, use the publicId to overwrite the URL. This is what the kiosk does, and it is cleaner than the URL.
                // It URL encodes/decodes properly, and doesn't contain possibly incorrect format strings in the path that sometimes need to be removed.
                const videoUrl = NINTENDO_COM_VIDEO_URL_PREFIX.concat(gallery.publicId);
                return Object.assign(Object.assign({}, gallery), { url: videoUrl, posterUrl: yield getVideoPosterImageUrl(videoUrl) });
            }))));
        }))();
    }, []);
    if (!videos) {
        return React.createElement(LoadingDimmer, null);
    }
    return (React.createElement("div", { className: "tab-pane fade active show", id: "images", role: "tabpanel", "aria-labelledby": "video-tab" },
        React.createElement("div", null,
            React.createElement("div", null, videos.map((video) => {
                var _a;
                return (
                // eslint-disable-next-line jsx-a11y/media-has-caption
                React.createElement("video", { className: "product-video mb-2", key: `video-${video.publicId}`, controls: !((_a = authContext.kioskInfo) === null || _a === void 0 ? void 0 : _a.hasControl), poster: video.posterUrl, "data-testid": `video-${video.publicId}`, onClick: () => triggerOnClick(authContext, {
                        type: 'video',
                        id: video.publicId,
                        index: 0,
                    }) },
                    React.createElement("source", { src: video.url, type: "video/mp4" })));
            })))));
}
export default VideosTab;
