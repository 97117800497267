import React from 'react';
import { Button, Image } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import localization from '../../locale/localization';
import { quizResults } from './QuestionList';
import '../../../assets/css/components/Quiz.scss';
const getResult = (correctAnswerCount) => {
    switch (correctAnswerCount) {
        case 0:
        case 1:
        case 2:
            return quizResults.selectiveMemory;
        case 3:
        case 4:
        case 5:
        case 6:
            return quizResults.playerWithPotential;
        case 7:
        case 8:
            return quizResults.quizMaster;
        default:
            return quizResults.selectiveMemory;
    }
};
function QuizResults({ correctAnswerCount, questionCount, restartQuiz, }) {
    const navigate = useNavigate();
    const quizResult = getResult(correctAnswerCount);
    return (React.createElement("div", { className: "container px-3 quiz-container" },
        React.createElement("div", { className: "p-2 text-center border shadow-sm quiz-tile results-image-container" },
            React.createElement("div", { className: "results-image-overlay" },
                React.createElement("div", null, quizResult.overlayText),
                React.createElement("div", null,
                    "You got ",
                    correctAnswerCount,
                    " out of ",
                    questionCount,
                    " correct!")),
            React.createElement(Image, { src: quizResult.image, fluid: true, alt: "" })),
        React.createElement("div", { className: "retry-text" }, quizResult.retryText),
        React.createElement("div", { className: "text-center" },
            React.createElement(Button, { className: "quiz-button mx-5", onClick: restartQuiz }, localization.quiz.takeAgain),
            React.createElement(Button, { className: "quiz-button", onClick: () => navigate(`/categories`) }, localization.quiz.backToActivities))));
}
export default QuizResults;
