import React from 'react';
import { Button, Image } from 'react-bootstrap';
import BreadCrumbs from '../common/BreadCrumbs';
import '../../../assets/css/components/Quiz.scss';
import localization from '../../locale/localization';
function Question({ quizQuestion, selectedAnswerId, selectAnswer, getNextQuestion, questionNumber, questionCount, }) {
    const answerImage = (indexId) => {
        const answer = quizQuestion.answers[indexId];
        return (React.createElement("div", { className: `shadow-sm quiz-tile ${selectedAnswerId === quizQuestion.correctAnswerId &&
                selectedAnswerId === answer.id
                ? 'selected-answer-correct'
                : ''} ${selectedAnswerId != null &&
                selectedAnswerId !== quizQuestion.correctAnswerId &&
                selectedAnswerId === answer.id
                ? 'selected-answer-incorrect'
                : ''}` },
            React.createElement("div", { className: "answer-image" },
                selectedAnswerId != null &&
                    selectedAnswerId !== quizQuestion.correctAnswerId &&
                    selectedAnswerId === answer.id && (React.createElement("div", { className: "answer-image-overlay" }, "Try again!")),
                React.createElement(Image, { src: answer.image, fluid: true, onClick: () => selectAnswer(answer.id), "data-testid": `answer-image-${answer.id}` }),
                React.createElement("div", { className: "answer-text" }, answer.text))));
    };
    return (React.createElement("div", { className: "container px-3 quiz-container" },
        React.createElement(BreadCrumbs, { showSelectedCategory: false }),
        React.createElement("h3", { className: "quiz-title mb-3" }, localization.quiz.title),
        React.createElement("div", { className: "p-2 text-center border shadow-sm quiz-tile" },
            React.createElement("div", { className: selectedAnswerId === quizQuestion.correctAnswerId ? `answered-gradient` : '' },
                React.createElement("div", { className: "question-overlay question-count-overlay" },
                    "Question: ",
                    questionNumber,
                    " of ",
                    questionCount),
                selectedAnswerId === quizQuestion.correctAnswerId && (React.createElement("div", { className: "question-overlay answered-question-overlay" },
                    React.createElement("div", { className: "answered-question-header" },
                        React.createElement("i", { className: "fas fa-check-circle" }),
                        ` ${quizQuestion.answered.header}`),
                    React.createElement("div", { className: "answered-question-text" }, quizQuestion.answered.description))),
                React.createElement(Image, { src: selectedAnswerId === quizQuestion.correctAnswerId
                        ? quizQuestion.answered.image
                        : quizQuestion.questionImage, fluid: true, alt: "" })),
            selectedAnswerId === quizQuestion.correctAnswerId ? (React.createElement("div", null,
                React.createElement(Button, { className: "quiz-button", onClick: getNextQuestion },
                    "Next Question ",
                    React.createElement("i", { className: "fas fa-chevron-right" })))) : (React.createElement("div", { className: "question-text" }, quizQuestion.questionText))),
        React.createElement("div", { className: "mx-4" },
            React.createElement("div", { className: "row mt-4 mb-2" },
                React.createElement("div", { className: "col-6 pr-0 text-center" }, answerImage(0)),
                React.createElement("div", { className: "col-6 pl-1 text-center" }, answerImage(1))),
            React.createElement("div", { className: "row mt-4 mb-2" },
                React.createElement("div", { className: "col-6 pr-0 text-center" }, answerImage(2)),
                React.createElement("div", { className: "col-6 pl-1 text-center" }, answerImage(3))))));
}
export default Question;
