const data = {
    login: {
        welcome: 'Welcome to the Nintendo Endless Aisle',
        intro: 'Log in to your Nintendo Account to view your personalized shopping experience. You’ll see recommendations based on your play data, wish list items in store, and more!',
        login: 'Log In',
        guest: 'Continue as Guest',
    },
    navigation: {
        home: 'Home',
        retry: 'Retry',
    },
    header: {
        banner: {
            href: '/#',
            aria: {
                bannerLabel: 'Retail Interactive',
            },
        },
        title: {
            fullTitle: 'Retail Interactive',
        },
    },
    categories: {
        explore: 'Explore our Nintendo selection',
    },
    purchaseBB: 'Purchase from Bestbuy.com ',
    hardware: {
        'switch-oled': 'Nintendo Switch - OLED',
        'switch-classic': 'Nintendo Switch',
        'switch-lite': 'Nintendo Switch Lite',
        compare: 'Compare Features',
        nso: 'Nintendo Switch Online',
        'gift-cards': 'Gift Cards',
    },
    quiz: {
        title: 'Nintendo Skill Quiz',
        takeAgain: 'Take the quiz again',
        backToActivities: 'Back to Categories',
    },
    inactivity: {
        title: 'Kiosk Disconnected',
        body: 'You have been disconnected from the Kiosk',
    },
};
export default data;
