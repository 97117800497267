var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import config from 'ConfigData';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import AuthenticationContext, { UserType, } from './AuthenticationContext';
import { authorizeUser } from '../api/authenticationApi';
import { captureKiosk, releaseKiosk } from '../api/kioskControlApi';
const LOCAL_STORAGE_AUTH_KEY = 'RETAIL_INTERACTIVES_AUTH_KEY';
const LOCAL_STORAGE_KEY_KIOSK_INFO = 'LOCAL_STORAGE_KEY_KIOSK_INFO';
export const KIOSK_INACTIVE_STATE = 'KIOSK_INACTIVE_STATE';
/**
 * This context provides the accountInfo with login/logout methods and the kioskInfo with control/release methods.
 *
 * The account info is pulled from local storage if available.
 *
 * If a kiosk code is provided on the URL as a query param, the new kiosk info is retrieved and saved into
 * local storage. If not in the query params, we try to pull kiosk info from local storage to check if they still
 * have control of a kiosk from a previous session.
 *
 * NOTE: This ContextProvider must be nested below a ReactRouter, because it uses react-router hooks to interact
 * with the query params on the URL.
 */
export function AuthenticationContextProvider({ children }) {
    // Set up state
    const [accountInfo, setAccountInfo] = useState(null);
    const [kioskInfo, setKioskInfo] = useState(null);
    const [capturingKiosk, setCapturingKiosk] = useState(false);
    const [handlingLogin, setHandlingLogin] = useState(false);
    const [kioskInactiveState, setKioskInactiveState] = useState(false);
    const [search, setSearch] = useSearchParams();
    // Set up auth functions
    const handleLogin = (userType) => __awaiter(this, void 0, void 0, function* () {
        setHandlingLogin(true);
        let naToken = '';
        if (userType === UserType.NINTENDO_ACCOUNT) {
            yield window.Alps.Api.showLogin();
            const userData = yield window.Alps.Api.requestSessionData((data) => data);
            naToken = userData.code;
        }
        const authToken = yield authorizeUser(userType, naToken);
        if (authToken) {
            const newAccountInfo = { userType, token: authToken };
            setAccountInfo(newAccountInfo);
            localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, JSON.stringify(newAccountInfo));
        }
        else {
            handleLogout();
        }
        setHandlingLogin(false);
    });
    const handleLogout = () => {
        setAccountInfo(null);
        localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
    };
    // Set up kiosk functions
    const handleControlKiosk = (kioskId, oneTimeCode) => __awaiter(this, void 0, void 0, function* () {
        if (accountInfo) {
            setCapturingKiosk(true);
            const kioskControlResponse = yield captureKiosk(accountInfo.token, kioskId, oneTimeCode);
            if (kioskControlResponse) {
                const newKioskInfo = {
                    kioskId: kioskControlResponse.kioskId,
                    hasControl: true,
                    expires: kioskControlResponse.expires,
                };
                setKioskInfo(newKioskInfo);
                setKioskInactiveState(false);
                localStorage.setItem(LOCAL_STORAGE_KEY_KIOSK_INFO, JSON.stringify(newKioskInfo));
                localStorage.removeItem(KIOSK_INACTIVE_STATE);
            }
            setCapturingKiosk(false);
        }
    });
    const handleReleaseKiosk = (inactive) => __awaiter(this, void 0, void 0, function* () {
        if (accountInfo && kioskInfo) {
            yield releaseKiosk(accountInfo.token, kioskInfo.kioskId);
        }
        if (inactive) {
            setKioskInactiveState(true);
        }
        setKioskInfo(null);
        localStorage.removeItem(LOCAL_STORAGE_KEY_KIOSK_INFO);
    });
    // Alps Setup
    useEffect(() => {
        window.alpsClientInfo = {
            clientId: config.nintendoAccountClientId,
            scope: ['openid', 'offline', 'user', 'user.mii', 'user.basic'],
        };
        // Create the Alps script tag. When it's loaded we can render our app.
        const script = document.createElement('script');
        script.src = config.alpsJs;
        document.head.appendChild(script);
    }, []);
    // Get kiosk code from query params
    useEffect(() => {
        // This effect will fire any time the query string parameters change.
        // If they do, we want to see if there are kiosk parameters, and, if so, set them.
        // If not, do nothing.
        // Try and get the kiosk id from the url query params on load
        const kioskId = search.get('kiosk_id');
        const oneTimeCode = search.get('one_time_code');
        if (kioskId && oneTimeCode) {
            setKioskInfo({ kioskId, hasControl: false, oneTimeCode });
            // Remove the kiosk code from the url search params
            setSearch({});
            // Log the user out. This ensures a fresh JWT token is created before each capture, so that it doesn't expire while the capture record is still valid.
            // Do this after setting the kioskInfo context above so that any syncs/triggers will not be performed since hasControl is false.
            // The user will then end up on the login page. Login will set a new accountInfo. And at that time both kioskInfo and accountInfo are now set, so capture will be attempted.
            handleLogout();
        }
    }, [search]);
    // Get kiosk info from local storage
    useEffect(() => {
        // This effect will only fire off on app load.
        // If we have kiosk related query string parameters, do nothing. That will be handled by a different effect.
        // If not, then handle any kiosk info in localStorage.
        if (!(search && search.get('kiosk_id') && search.get('one_time_code'))) {
            (() => __awaiter(this, void 0, void 0, function* () {
                // Try to load previous kiosk info from local storage
                const previousKioskInfo = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY_KIOSK_INFO));
                if (previousKioskInfo) {
                    if (previousKioskInfo.expires &&
                        dayjs(previousKioskInfo.expires).isBefore(dayjs())) {
                        // If the previous kiosk code is past expiration, release it
                        yield handleReleaseKiosk();
                    }
                    else {
                        setKioskInfo(previousKioskInfo);
                    }
                }
            }))();
        }
    }, []);
    // Get account info from local storage
    useEffect(() => {
        const storageAuthValueString = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
        try {
            const storedAccountInfo = storageAuthValueString
                ? JSON.parse(storageAuthValueString)
                : null;
            setAccountInfo(storedAccountInfo);
        }
        catch (e) {
            // Something's wrong with the currently stored account info, clear it out and make them start over
            localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
        }
    }, []);
    // Attempt control once accountInfo and kioskInfo are set.
    // Only if kiosk info isn't already under control.
    useEffect(() => {
        // If user logs in, and they do not have control of the kiosk that they want, try and capture the kiosk
        if (accountInfo && kioskInfo && !kioskInfo.hasControl) {
            handleControlKiosk(kioskInfo.kioskId, kioskInfo.oneTimeCode);
        }
    }, [accountInfo, kioskInfo]);
    const authContextValue = useMemo(() => ({
        accountInfo,
        handleLogin,
        handleLogout,
        kioskInfo,
        handleControlKiosk,
        handleReleaseKiosk,
        capturingKiosk,
        handlingLogin,
        kioskInactiveState,
    }), [accountInfo, kioskInfo, capturingKiosk, handlingLogin, kioskInactiveState]);
    return (React.createElement(AuthenticationContext.Provider, { value: authContextValue }, children));
}
/**
 * A helpful hook for using this context.
 */
export function useAuthentication() {
    return useContext(AuthenticationContext);
}
