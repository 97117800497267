import React from 'react';
import { Nav } from 'react-bootstrap';
import NintendoLogo from '../../../assets/images/header/nintendo-logo.svg';
import '../../../assets/css/components/Header.scss';
import localization from '../../locale/localization';
function Header() {
    return (React.createElement("header", { className: "header" },
        React.createElement(Nav, { className: "top-bar-container" },
            React.createElement("div", { className: "top-bar" },
                React.createElement("a", { className: "banner", href: localization.header.banner.href, "aria-label": localization.header.banner.aria.bannerLabel },
                    React.createElement(NintendoLogo, { className: "nintendo-logo", "data-testid": "nintendo-logo" }))))));
}
export default Header;
