var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import config from 'ConfigData';
import axios from 'axios';
/**
 * Get all categories from the /categories endpoint (currently a static json file) and update our cached map of
 * categoryId -> category.
 * After retrieving categories and refreshing cache, return all categories that are children of the given
 * parentCategoryId.
 * If no parentCategoryId is null or undefined, return all 'top-level' categories, aka all that have children.
 *
 * TODO: LARM-76
 *  Currently this is a static json file that is distributed together with this web app.
 *  In phase 1, we did not have time to make this its own endpoint.
 *  If we have time after initial implementation, we will improve this by adding a graph endpoint for retrieving the
 *  categories from the longarm catalog graph.
 *
 * */
export function loadCategories() {
    return __awaiter(this, void 0, void 0, function* () {
        const categories = new Map();
        try {
            const categoryResponse = yield axios.get(`${config.retailInteractiveConsumerWebAppBaseUrl}/category_assets/categories.json`);
            categoryResponse.data.forEach((category) => {
                categories.set(category.id, category);
            });
        }
        catch (error) {
            categories.clear();
        }
        return categories;
    });
}
