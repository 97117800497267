var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useContext, useEffect, useMemo, useState } from 'react';
import CategoriesContext from './CategoriesContext';
import { loadCategories } from '../api/categoryApi';
import { getCategories, getCategoryForId, getParentCategoryForChildCategoryId, } from './CategoriesContextUtil';
export function CategoriesContextProvider({ children }) {
    const [categories, setCategories] = useState(null);
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            setCategories(yield loadCategories());
        }))();
    }, []);
    const categoriesContextValue = useMemo(() => ({
        categories,
        getCategories: (parentCategoryId) => getCategories(categories, parentCategoryId),
        getCategoryForId: (categoryId) => getCategoryForId(categoryId, categories),
        getParentCategoryForChildCategoryId: (childCategoryId) => getParentCategoryForChildCategoryId(childCategoryId, categories),
    }), [categories]);
    return (React.createElement(CategoriesContext.Provider, { value: categoriesContextValue }, children));
}
export function useCategories() {
    return useContext(CategoriesContext);
}
