import React from 'react';
import HTMLReactParser from 'html-react-parser';
import DOMPurify from 'dompurify';
import dayjs from 'dayjs';
import config from 'ConfigData';
import { CATEGORY_HARDWARE } from '../ProductDetails';
function OverviewTab({ nintendoProduct }) {
    // Display Hardware category
    if (nintendoProduct.productType === CATEGORY_HARDWARE) {
        return (React.createElement("div", { className: "card mx-0" },
            React.createElement("div", { className: "card-body" },
                React.createElement("img", { className: "img-fluid", src: `${config.retailInteractiveConsumerWebAppBaseUrl}/category_assets/images/hardware/${nintendoProduct.description}.png`, alt: "", "data-testid": "hardware-category-card" }))));
    }
    // Display Nintendo product
    return (React.createElement(React.Fragment, null,
        nintendoProduct.description && (React.createElement("div", null, HTMLReactParser(DOMPurify.sanitize(nintendoProduct.description, { FORBID_TAGS: ['a'] })))),
        React.createElement("dl", { className: "striped row" },
            nintendoProduct.contentRating && (React.createElement(React.Fragment, null,
                React.createElement("dt", { className: "col-5" }, "Rating: "),
                React.createElement("dd", { className: "col-7" },
                    React.createElement("img", { className: "rating-image", src: nintendoProduct.contentRating.icon.url, alt: `Product ${nintendoProduct.name}` }),
                    React.createElement("div", null, nintendoProduct.contentDescriptors &&
                        nintendoProduct.contentDescriptors
                            .map((descriptor) => descriptor.label)
                            .join(', '))))),
            nintendoProduct.platform.label && (React.createElement(React.Fragment, null,
                React.createElement("dt", { className: "col-5" }, "Platform: "),
                React.createElement("dd", { className: "col-7" }, nintendoProduct.platform.label))),
            nintendoProduct.softwarePublisher && (React.createElement(React.Fragment, null,
                React.createElement("dt", { className: "col-5" }, "Publisher: "),
                React.createElement("dd", { className: "col-7" }, nintendoProduct.softwarePublisher))),
            (nintendoProduct.playersMin ||
                nintendoProduct.playersMinLocal ||
                nintendoProduct.playersMinOnline) && (React.createElement(React.Fragment, null,
                React.createElement("dt", { className: "col-5" }, "Players: "),
                React.createElement("dd", { className: "col-7" },
                    nintendoProduct.playersMin && nintendoProduct.playersMax && (React.createElement("div", null,
                        "Single System: (",
                        nintendoProduct.playersMin,
                        " -",
                        ' ',
                        nintendoProduct.playersMax,
                        ")")),
                    nintendoProduct.playersMinLocal && nintendoProduct.playersMaxLocal && (React.createElement("div", null,
                        "Local Wireless: (",
                        nintendoProduct.playersMinLocal,
                        " -",
                        ' ',
                        nintendoProduct.playersMaxLocal,
                        ")")),
                    nintendoProduct.playersMinOnline && nintendoProduct.playersMaxOnline && (React.createElement("div", null,
                        "Online: (",
                        nintendoProduct.playersMinOnline,
                        " -",
                        ' ',
                        nintendoProduct.playersMaxOnline,
                        ")"))))),
            nintendoProduct.genres && nintendoProduct.genres.length !== 0 && (React.createElement(React.Fragment, null,
                React.createElement("dt", { className: "col-5" }, "Genre: "),
                React.createElement("dd", { className: "col-7" }, nintendoProduct.genres.map((genre) => genre.label).join(', ')))),
            nintendoProduct.playModes && nintendoProduct.playModes.length !== 0 && (React.createElement(React.Fragment, null,
                React.createElement("dt", { className: "col-5" }, "Supported Modes: "),
                React.createElement("dd", { className: "col-7" }, nintendoProduct.playModes.map((mode) => mode.label).join(', ')))),
            nintendoProduct.releaseDate && (React.createElement(React.Fragment, null,
                React.createElement("dt", { className: "col-5" }, "Release Date: "),
                React.createElement("dd", { className: "col-7" }, dayjs(nintendoProduct.releaseDate).format('MMMM Do, YYYY')))),
            nintendoProduct.supportedLanguages &&
                nintendoProduct.supportedLanguages.length !== 0 && (React.createElement(React.Fragment, null,
                React.createElement("dt", { className: "col-5" }, "Supported Languages: "),
                React.createElement("dd", { className: "col-7" }, nintendoProduct.supportedLanguages
                    .map((language) => language)
                    .join(', ')))),
            nintendoProduct.disclaimer && (React.createElement("div", null, HTMLReactParser(DOMPurify.sanitize(nintendoProduct.disclaimer, { FORBID_TAGS: ['a'] })))))));
}
export default OverviewTab;
