var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router';
import CategoryTile from './CategoryTile';
import '../../../assets/css/components/CategorySelector.scss';
import NavigateTo from '../common/NavigateTo';
import BreadCrumbs from '../common/BreadCrumbs';
import localization from '../../locale/localization';
import LoadingDimmer from '../common/LoadingDimmer';
import ErrorModal from '../common/ErrorModal';
import { useCategories } from '../../context/CategoriesContextProvider';
import { useAuthentication } from '../../context/AuthenticationContextProvider';
import { UserType } from '../../context/AuthenticationContext';
export const CATEGORY_RECOMMENDED = 'Recommended Games';
export const CATEGORY_BEST_SELLERS = 'Best Sellers';
export const CATEGORY_ACCESSORIES = 'Accessories';
export const CATEGORY_ACTIVITIES = 'Activities';
function CategorySelector() {
    const params = useParams();
    const authContext = useAuthentication();
    const categoriesContext = useCategories();
    const selectedCategoryId = params.categoryId;
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [categories, setCategories] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [redirectPath, setRedirectPath] = useState(null);
    useEffect(() => {
        if (!redirectPath && categoriesContext.categories) {
            (() => __awaiter(this, void 0, void 0, function* () {
                const newSelectedCategory = categoriesContext.getCategoryForId(selectedCategoryId);
                setSelectedCategory(newSelectedCategory);
                const updatedCategories = categoriesContext.getCategories(selectedCategoryId);
                if (!updatedCategories || updatedCategories.length < 1) {
                    setRedirectPath(`/categories/${selectedCategoryId}/products`);
                }
                setCategories(updatedCategories);
                if (updatedCategories) {
                    setError(false);
                }
                else {
                    setError(true);
                }
                setLoading(false);
            }))();
        }
    }, [selectedCategoryId, categoriesContext]);
    if (loading)
        return React.createElement(LoadingDimmer, null);
    if (error) {
        return React.createElement(ErrorModal, null);
    }
    if (redirectPath)
        return React.createElement(NavigateTo, { to: redirectPath });
    return (React.createElement(Container, { className: "p-0" },
        React.createElement("div", { className: "category-selector" },
            React.createElement("div", { className: "category-selector-title-and-breadcrumb" }, selectedCategory ? (React.createElement("div", null,
                React.createElement(BreadCrumbs, { showSelectedCategory: false }),
                React.createElement("h3", { className: "category-selector-title mb-3" }, selectedCategory.name))) : (React.createElement("div", { className: "top-level-category" },
                React.createElement("h3", { className: "category-selector-title" }, localization.categories.explore)))),
            categories
                .filter((category) => {
                var _a, _b;
                return ![CATEGORY_RECOMMENDED, CATEGORY_BEST_SELLERS].includes(category.name) ||
                    (category.name === CATEGORY_RECOMMENDED &&
                        ((_a = authContext.accountInfo) === null || _a === void 0 ? void 0 : _a.userType) === UserType.NINTENDO_ACCOUNT) ||
                    (category.name === CATEGORY_BEST_SELLERS &&
                        ((_b = authContext.accountInfo) === null || _b === void 0 ? void 0 : _b.userType) === UserType.GUEST);
            })
                .map((category) => (React.createElement(CategoryTile, { category: category, key: `category-tile-${category.name}` }))))));
}
export default CategorySelector;
