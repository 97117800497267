import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../../../assets/css/components/ProductTile.scss';
function ProductTileDisplay({ linkPath, imageUrl, productName }) {
    return (React.createElement(Container, { className: "px-3" },
        React.createElement(Link, { className: "text-decoration-none", to: { pathname: linkPath } },
            React.createElement(Card, { className: "product-tile flex-row mx-0" },
                React.createElement("div", { className: "product-image-container col-6" },
                    React.createElement("img", { className: "product-image", src: imageUrl, alt: `Product ${productName}` })),
                React.createElement("div", { className: "product-info-container col-6" },
                    React.createElement("h4", { className: "product-tile-title" }, productName))))));
}
export default ProductTileDisplay;
