import React, { useState } from 'react';
import { Col, Row, Image, Carousel } from 'react-bootstrap';
import '../../../../assets/css/components/ImagesTab.scss';
import { useAuthentication } from '../../../context/AuthenticationContextProvider';
import { triggerOnClick } from '../../../util/KioskUtil';
function ImagesColumnLayout({ imageGallery, productName }) {
    const authContext = useAuthentication();
    const [overlay, setOverlay] = useState(false);
    const [carouselIndex, setCarouselIndex] = useState(0);
    const toggleOverlay = () => {
        setOverlay(!overlay);
    };
    const handleSelect = (selectedIndex) => {
        setCarouselIndex(selectedIndex);
    };
    const imageRow = (leftImage, rightImage, imageIndex) => (React.createElement(Row, { className: "mb-2", key: `image-${leftImage.publicId}` },
        leftImage && imageCol(leftImage, productName, imageIndex),
        rightImage && imageCol(rightImage, productName, imageIndex + 1)));
    const imageCol = (image, name, imageIndex) => (React.createElement(Col, null,
        React.createElement(Image, { src: image.url, alt: `Product ${name}`, fluid: true, onClick: () => {
                var _a;
                if (!((_a = authContext.kioskInfo) === null || _a === void 0 ? void 0 : _a.hasControl)) {
                    toggleOverlay();
                    handleSelect(imageIndex);
                }
                triggerOnClick(authContext, {
                    type: 'image',
                    id: image.publicId,
                    index: 0,
                });
            }, "data-testid": `product-image-${image.publicId}` })));
    return (imageGallery && (React.createElement(React.Fragment, null,
        imageGallery.map((leftImage, index) => index % 2 === 0 &&
            imageRow(leftImage, index + 1 < imageGallery.length ? imageGallery[index + 1] : null, index)),
        overlay && (React.createElement("div", { className: "overlay-modal" },
            React.createElement("div", { onClick: toggleOverlay, className: "overlay", role: "presentation" }),
            React.createElement(Carousel, { className: "overlay-content", fade: true, activeIndex: carouselIndex, onSelect: handleSelect }, imageGallery.map((image) => (React.createElement(Carousel.Item, { key: `carousel-product-image-${image.publicId}` },
                React.createElement(Image, { src: image.url, alt: `Product ${productName}`, fluid: true, "data-testid": `carousel-product-image-${image.publicId}` }))))))))));
}
export default ImagesColumnLayout;
