/**
 * @param categories
 * @param parentCategoryId Id of the parent category to get children of.
 * @return Categories under the given parentCategoryId.
 */
export function getCategories(categories, parentCategoryId) {
    let categoriesToReturn;
    if (parentCategoryId != null) {
        // If parent category id is given, retrieve all categories that are children of the provided parent category.
        const parentCategory = categories.get(parentCategoryId);
        if (parentCategory) {
            categoriesToReturn = [];
            const childCategories = parentCategory.children;
            if (childCategories && childCategories.length > 0) {
                childCategories.forEach((childId) => {
                    categoriesToReturn.push(categories.get(childId));
                });
            }
        }
    }
    else {
        // If parent category id is not provided, retrieve all categories that have children.
        // We only need 2 levels of categories because marketing wants minimum number of clicks to get to a product
        // details page. So, categories that have children are 'top-level' and categories with no children are
        // 'bottom-level'.
        categoriesToReturn = Array.from(categories.values()).filter((category) => category.topLevel);
    }
    return categoriesToReturn || null;
}
/**
 * Get the cached category object for the given categoryId.
 *
 * @param categoryId Category to retrieve
 * @param categories
 * @return Found category or null if not found.
 */
export function getCategoryForId(categoryId, categories) {
    const categoryToReturn = categories.get(categoryId);
    return categoryToReturn || null;
}
/**
 * Get the cached parent category object for the given childCategoryId.
 *
 * @param childCategoryId Id of the child category that we want to find the parent of.
 * @param categories
 * @return Parent category for the given childCategoryId; null if not found.
 */
export function getParentCategoryForChildCategoryId(childCategoryId, categories) {
    const categoryToReturn = Array.from(categories.values()).find((category) => category && category.children && category.children.includes(childCategoryId));
    return categoryToReturn || null;
}
