var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import config from 'ConfigData';
import axios from 'axios';
export function captureKiosk(jwtToken, kioskId, oneTimeCode) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const authResponse = yield axios.post(`${config.retailInteractiveServiceBaseUrl}/consumer/kiosk/capture/${kioskId}/${oneTimeCode}`, null, { headers: { Authorization: jwtToken } });
            if (authResponse.status === 200) {
                return authResponse.data;
            }
            return null;
        }
        catch (error) {
            return null;
        }
    });
}
export function releaseKiosk(jwtToken, kioskId) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const authResponse = yield axios.delete(`${config.retailInteractiveServiceBaseUrl}/consumer/kiosk/capture/${kioskId}`, { headers: { Authorization: jwtToken } });
            return authResponse.status === 200;
        }
        catch (error) {
            return false;
        }
    });
}
export function syncKiosk(jwtToken, kioskId, kioskSync, handleRelease) {
    return __awaiter(this, void 0, void 0, function* () {
        return syncOrTriggerKiosk(jwtToken, kioskId, kioskSync, 'sync', handleRelease);
    });
}
export function triggerKiosk(jwtToken, kioskId, kioskTrigger, handleRelease) {
    return __awaiter(this, void 0, void 0, function* () {
        return syncOrTriggerKiosk(jwtToken, kioskId, kioskTrigger, 'trigger', handleRelease);
    });
}
function syncOrTriggerKiosk(jwtToken, kioskId, kioskCommand, path, handleRelease) {
    return __awaiter(this, void 0, void 0, function* () {
        let responseStatus = null;
        yield axios
            .post(`${config.retailInteractiveServiceBaseUrl}/consumer/kiosk/${path}/${kioskId}`, kioskCommand, { headers: { Authorization: jwtToken } })
            .then((response) => {
            responseStatus = response.status;
        })
            .catch((error) => __awaiter(this, void 0, void 0, function* () {
            if (error.response.status === 409) {
                yield handleRelease(true);
            }
            responseStatus = error.response.status;
        }));
        return responseStatus === 200 || responseStatus === 409;
    });
}
