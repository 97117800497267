var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { Button } from 'react-bootstrap';
import config from 'ConfigData';
import { useLazyQuery } from '@apollo/client';
import ProductTile from './ProductTile';
import BreadCrumbs from '../common/BreadCrumbs';
import { accessoryList, getProductsForRecommendations, } from '../../api/productApi';
import '../../../assets/css/components/ProductSelector.scss';
import { syncOnClick } from '../../util/KioskUtil';
import { useAuthentication } from '../../context/AuthenticationContextProvider';
import LoadingDimmer from '../common/LoadingDimmer';
import ErrorModal from '../common/ErrorModal';
import { useCategories } from '../../context/CategoriesContextProvider';
import { CATEGORY_ACCESSORIES, CATEGORY_RECOMMENDED } from '../category/CategorySelector';
import { SyncType } from '../../types/longarmTypes';
import { PRODUCT_IDS_FOR_CATEGORY } from '../../api/productGraph';
const pageSize = 8;
function ProductSelector() {
    const authContext = useAuthentication();
    const params = useParams();
    const categoriesContext = useCategories();
    const selectedCategoryId = params.categoryId;
    const [categoryName, setCategoryName] = useState('');
    const [productIds, setProductIds] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [pagination, setPagination] = useState({
        pageNum: 0,
        startIndex: 0,
        endIndex: pageSize,
    });
    const [getNintendoProducts, { data: nintendoProducts, error: productQueryError }] = useLazyQuery(PRODUCT_IDS_FOR_CATEGORY, {
        variables: {
            categoryId: selectedCategoryId,
            retailerId: config.bbRetailerId,
        },
    });
    useEffect(() => {
        if (categoriesContext) {
            (() => __awaiter(this, void 0, void 0, function* () {
                const category = categoriesContext.getCategoryForId(selectedCategoryId);
                setCategoryName(category.name);
                // Separate accessories/recommended categories logic from rest of categories
                // to allow each to handle error, loading, and state changes appropriately
                if ([CATEGORY_ACCESSORIES, CATEGORY_RECOMMENDED].includes(category.name)) {
                    let productIdsResponse;
                    if (category.name === CATEGORY_ACCESSORIES) {
                        productIdsResponse = accessoryList.map((accessory) => ({
                            productId: accessory.id,
                            nsuid: undefined,
                        }));
                    }
                    else {
                        productIdsResponse = getProductsForRecommendations(authContext.accountInfo.token);
                    }
                    if (productIdsResponse) {
                        setProductIds(productIdsResponse);
                    }
                    else {
                        setError(true);
                    }
                    setLoading(false);
                }
                else {
                    // As the graphql query causes a state change, handle this case separately
                    yield getNintendoProducts();
                    if (productQueryError) {
                        setError(true);
                        setLoading(false);
                    }
                    if (nintendoProducts) {
                        if (nintendoProducts.products.length > 0) {
                            setProductIds(nintendoProducts.products);
                        }
                        else {
                            setError(true);
                        }
                        setLoading(false);
                    }
                }
            }))();
        }
    }, [categoriesContext, getNintendoProducts, nintendoProducts, productQueryError]);
    if (loading)
        return React.createElement(LoadingDimmer, null);
    if (error) {
        return React.createElement(ErrorModal, null);
    }
    return (React.createElement("div", { className: "product-selector" },
        React.createElement("div", { className: "py-2 px-3 d-flex justify-content-between" },
            React.createElement(BreadCrumbs, { showSelectedCategory: false }),
            React.createElement("div", { className: "d-flex justify-content-end pagination-container" },
                React.createElement(Button, { className: "btn btn-danger ml-3", disabled: pagination.pageNum === 0, onClick: () => {
                        setPagination({
                            pageNum: pagination.pageNum - 1,
                            startIndex: pagination.startIndex - pageSize,
                            endIndex: pagination.endIndex - pageSize,
                        });
                        syncOnClick(authContext, {
                            type: SyncType.CATEGORY,
                            id: selectedCategoryId,
                            subId: (pagination.pageNum - 1).toString(),
                        });
                    } },
                    React.createElement("i", { className: "fas fa-chevron-left" })),
                React.createElement("p", { className: "pt-2 mb-0 text-center pagination-text" },
                    React.createElement("strong", null,
                        pagination.startIndex + 1,
                        " \u2013",
                        ' ',
                        pagination.endIndex < productIds.length
                            ? pagination.endIndex
                            : productIds.length),
                    ' ',
                    "of ",
                    productIds.length),
                React.createElement(Button, { className: "btn btn-danger ml-3", disabled: pagination.pageNum * pageSize + pageSize >= productIds.length, onClick: () => {
                        setPagination({
                            pageNum: pagination.pageNum + 1,
                            startIndex: pagination.startIndex + pageSize,
                            endIndex: pagination.endIndex + pageSize,
                        });
                        syncOnClick(authContext, {
                            type: SyncType.CATEGORY,
                            id: selectedCategoryId,
                            subId: (pagination.pageNum + 1).toString(),
                        });
                    } },
                    React.createElement("i", { className: "fas fa-chevron-right" })))),
        React.createElement("div", { className: "product-selector-tiles" },
            React.createElement("h3", { className: "my-3 px-3 category-name" }, categoryName),
            productIds
                .slice(pagination.startIndex, pagination.endIndex)
                .map((productIdResponse) => (React.createElement(ProductTile, { nsuid: productIdResponse.nsuid, productId: productIdResponse.productId, selectedCategoryId: selectedCategoryId, 
                // Use productId when nsuid is not present to avoid duplicate tile IDs
                key: `product-tile-${productIdResponse.nsuid || productIdResponse.productId}` }))))));
}
export default ProductSelector;
