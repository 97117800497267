var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import config from 'ConfigData';
import axios from 'axios';
export function authorizeUser(userType, nintendoAccountToken) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const request = {
                userType: userType.toString(),
                nintendoAccountToken: nintendoAccountToken === null || nintendoAccountToken === void 0 ? void 0 : nintendoAccountToken.toString(),
            };
            const authResponse = yield axios.post(`${config.retailInteractiveServiceBaseUrl}/consumer/authorization/authorize`, request);
            if (authResponse.status === 200) {
                return authResponse.data.token;
            }
            return null;
        }
        catch (error) {
            return null;
        }
    });
}
