import React from 'react';
import { Button } from 'react-bootstrap';
import { UserType } from '../../context/AuthenticationContext';
import '../../../assets/css/components/LoginScreen.scss';
import { useAuthentication } from '../../context/AuthenticationContextProvider';
import localization from '../../locale/localization';
/**
 * Login screen that allows user to choose logging in with Nintendo Account or continuing as a guest.
 */
function LoginScreen() {
    const { handleLogin } = useAuthentication();
    return (React.createElement("div", { className: "container-scroll pt-4 px-3" },
        React.createElement("h1", null, localization.login.welcome),
        React.createElement("p", { className: "mt-3 mb-3" }, localization.login.intro),
        React.createElement("div", { className: "login-buttons" },
            React.createElement(Button, { className: "btn-login btn-lg w-100", onClick: () => handleLogin(UserType.NINTENDO_ACCOUNT) }, localization.login.login),
            React.createElement("div", { className: "text-center mt-3 mb-2 text-muted or-text" }, "or"),
            React.createElement(Button, { className: "btn-guest btn-lg w-100 mb-4", onClick: () => handleLogin(UserType.GUEST) }, localization.login.guest))));
}
export default LoginScreen;
