var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useParams } from 'react-router';
import { useLazyQuery } from '@apollo/client';
import config from 'ConfigData';
import BreadCrumbs from '../common/BreadCrumbs';
import { PRODUCT_BY_NSUID, PRODUCT_BY_SKU, PRODUCT_TYPE_SIMPLE, RETAILER_SKU, } from '../../api/productGraph';
import VideosTab from './tabs/VideosTab';
import ImagesTab from './tabs/ImagesTab';
import OverviewTab from './tabs/OverviewTab';
import { useAuthentication } from '../../context/AuthenticationContextProvider';
import { useCategories } from '../../context/CategoriesContextProvider';
import { syncOnClick } from '../../util/KioskUtil';
import LoadingDimmer from '../common/LoadingDimmer';
import ErrorModal from '../common/ErrorModal';
import '../../../assets/css/components/ProductDetails.scss';
import localization from '../../locale/localization';
import { accessoryList } from '../../api/productApi';
export const CATEGORY_HARDWARE = 'Hardware';
const getDefaultTab = (nintendoVideoGallery, nintendoImageGallery) => {
    if (nintendoVideoGallery && nintendoVideoGallery.length > 0) {
        return 'videos';
    }
    if (nintendoImageGallery && nintendoImageGallery.length > 0) {
        return 'images';
    }
    return 'overview';
};
const getAccessoryRetailerSKU = (id) => {
    const accessory = accessoryList.find((entry) => entry.id === id);
    return accessory ? accessory.retailerSku : '';
};
function ProductDetails({ syncType }) {
    const authContext = useAuthentication();
    const params = useParams();
    const categoriesContext = useCategories();
    const selectedCategoryId = params.categoryId;
    const selectedProductId = params.productId;
    const selectedNsuid = params.nsuid;
    const selectedAccessoryId = params.accessoryId;
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [showCategory, setShowCategory] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [nintendoVideoGallery, setNintendoVideoGallery] = useState([]);
    const [nintendoImageGallery, setNintendoImageGallery] = useState([]);
    const [retailerSKU, setRetailerSKU] = useState('');
    const [getNintendoProducts, { data: nintendoProducts, error: productQueryError }] = selectedAccessoryId
        ? useLazyQuery(PRODUCT_BY_SKU, {
            variables: {
                sku: selectedAccessoryId,
                locale: 'en_US',
            },
            context: { clientName: 'NintendoGraph' },
        })
        : useLazyQuery(PRODUCT_BY_NSUID, {
            variables: {
                nsuid: selectedNsuid,
                locale: 'en_US',
            },
            context: { clientName: 'NintendoGraph' },
        });
    const [getRetailerSku, { data: retailerSku, error: retailerSkuQueryError }] = useLazyQuery(RETAILER_SKU, {
        variables: {
            productId: selectedProductId,
            retailerId: config.bbRetailerId,
        },
    });
    useEffect(() => {
        (() => __awaiter(this, void 0, void 0, function* () {
            const selectedCategory = categoriesContext.getCategoryForId(selectedCategoryId);
            if (selectedCategory.type === CATEGORY_HARDWARE) {
                // We only need name, type, and tags to display a hardware category
                setSelectedProduct({
                    name: localization.hardware[selectedCategory.tags[0]],
                    productType: selectedCategory.type,
                    description: selectedCategory.tags[0],
                });
                setShowCategory(false);
                if (selectedCategory.productGallery) {
                    setNintendoVideoGallery(selectedCategory.productGallery);
                }
                setRetailerSKU(selectedCategory.retailerSKU ? selectedCategory.retailerSKU : '');
                setLoading(false);
            }
            else {
                yield getNintendoProducts();
                if (productQueryError) {
                    setError(true);
                    setLoading(false);
                }
                if (nintendoProducts) {
                    if (nintendoProducts.products.length > 0) {
                        const simpleProduct = nintendoProducts.products.find((product) => product.productType === PRODUCT_TYPE_SIMPLE);
                        setSelectedProduct(simpleProduct);
                        // Check that a product image/video gallery is attached to this product
                        if (simpleProduct.productGallery) {
                            setNintendoVideoGallery(simpleProduct.productGallery.filter((galleryElem) => galleryElem.resourceType === 'video'));
                            const imageGallery = simpleProduct.productGallery.filter((galleryElem) => galleryElem.resourceType === 'image');
                            if (selectedAccessoryId) {
                                imageGallery.unshift(simpleProduct.productImage);
                            }
                            setNintendoImageGallery(imageGallery);
                        }
                        if (selectedAccessoryId) {
                            setRetailerSKU(getAccessoryRetailerSKU(selectedAccessoryId));
                        }
                        else {
                            yield getRetailerSku();
                            if (retailerSkuQueryError) {
                                setError(true);
                            }
                            if (retailerSku) {
                                if (retailerSku.sku.length > 0) {
                                    setRetailerSKU(retailerSku.sku[0].retailerProducts[0].retailerProductSku);
                                }
                                else {
                                    setError(true);
                                }
                            }
                        }
                    }
                    else {
                        setError(true);
                    }
                    setLoading(false);
                }
            }
        }))();
    }, [nintendoProducts, retailerSku, productQueryError, retailerSkuQueryError]);
    if (loading)
        return React.createElement(LoadingDimmer, null);
    if (error) {
        return React.createElement(ErrorModal, null);
    }
    return (React.createElement(Container, { className: "px-3" },
        React.createElement("div", { className: "py-2 d-flex" },
            React.createElement(BreadCrumbs, { showSelectedCategory: showCategory })),
        React.createElement("h2", { className: "fw-bold" }, selectedProduct ? selectedProduct.name : null),
        retailerSKU && (React.createElement("div", { className: "button-container" },
            React.createElement(Button, { type: "button", className: "btn w-100 btn-danger purchase-button", onClick: () => {
                    authContext.handleReleaseKiosk();
                    window.location.replace(`https://www.bestbuy.com/site/${retailerSKU}.p?skuId=${retailerSKU}`);
                } },
                localization.purchaseBB,
                React.createElement("i", { className: "ml-1 fas fa-external-link-alt" })))),
        nintendoImageGallery.length === 0 && nintendoVideoGallery.length === 0 ? (React.createElement(OverviewTab, { nintendoProduct: selectedProduct })) : (React.createElement(Tabs, { defaultActiveKey: getDefaultTab(nintendoVideoGallery, nintendoImageGallery), className: "nav nav-fill nav-justified nav-product nav-pills flex-sm-row my-3", onSelect: (eventKey) => syncOnClick(authContext, {
                type: syncType,
                id: selectedAccessoryId || selectedNsuid || selectedCategoryId,
                subId: eventKey,
            }).then((syncResult) => {
                if (!syncResult) {
                    setError(true);
                }
            }) },
            nintendoVideoGallery && nintendoVideoGallery.length > 0 && (React.createElement(Tab, { className: "nav-fill flex-column", eventKey: "videos", title: "Videos" },
                React.createElement(VideosTab, { videoProductGallery: nintendoVideoGallery }))),
            nintendoImageGallery &&
                nintendoImageGallery.length > 0 &&
                selectedProduct &&
                selectedProduct.name && (React.createElement(Tab, { eventKey: "images", title: "Images" },
                React.createElement(ImagesTab, { imageProductGallery: nintendoImageGallery, selectedProductName: selectedProduct.name }))),
            React.createElement(Tab, { eventKey: "overview", title: "Overview" },
                React.createElement(OverviewTab, { nintendoProduct: selectedProduct }))))));
}
export default ProductDetails;
