import React from 'react';
import Loading from 'react-loading';
import '../../../assets/css/components/LoadingDimmer.scss';
function LoadingDimmer() {
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "dimmer" }),
        React.createElement("div", { className: "loading-spinner" },
            React.createElement(Loading, { type: "spinningBubbles", color: "gray" }))));
}
export default LoadingDimmer;
