import React from 'react';
import { useAuthentication } from '../../context/AuthenticationContextProvider';
import LoginScreen from './LoginScreen';
/**
 * Component that renders the login screen if no account info was found in the AuthenticationContext.
 *
 * NOTE: This component should be nested at some level under an AuthenticationContext and a ReactRouter (since
 * AuthenticationContext requires a ReactRouter)
 */
export default function AuthenticatedRoute({ children }) {
    const { accountInfo } = useAuthentication();
    if (!accountInfo) {
        return React.createElement(LoginScreen, null);
    }
    return React.createElement("div", { className: "d-flex" }, children);
}
