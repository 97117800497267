var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import config from 'ConfigData';
export function quizStart(jwtToken, kioskId, kioskAnswerOrder, handleRelease) {
    return __awaiter(this, void 0, void 0, function* () {
        return quizCommand(jwtToken, kioskId, kioskAnswerOrder, 'start', handleRelease);
    });
}
export function quizSelect(jwtToken, kioskId, kioskAnswerSelect, handleRelease) {
    return __awaiter(this, void 0, void 0, function* () {
        return quizCommand(jwtToken, kioskId, kioskAnswerSelect, 'select', handleRelease);
    });
}
export function quizNext(jwtToken, kioskId, kioskAnswerOrder, handleRelease) {
    return __awaiter(this, void 0, void 0, function* () {
        return quizCommand(jwtToken, kioskId, kioskAnswerOrder, 'next', handleRelease);
    });
}
export function quizResults(jwtToken, kioskId, handleRelease) {
    return __awaiter(this, void 0, void 0, function* () {
        return quizCommand(jwtToken, kioskId, null, 'next', handleRelease);
    });
}
function quizCommand(jwtToken, kioskId, kioskAnswerOrder, path, handleRelease) {
    return __awaiter(this, void 0, void 0, function* () {
        let responseStatus = null;
        yield axios
            .post(`${config.retailInteractiveServiceBaseUrl}/consumer/kiosk/quiz/${path}/${kioskId}`, kioskAnswerOrder, { headers: { Authorization: jwtToken } })
            .then((response) => {
            responseStatus = response.status;
        })
            .catch((error) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            if (((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 409) {
                yield handleRelease();
            }
            responseStatus = (_b = error.response) === null || _b === void 0 ? void 0 : _b.status;
        }));
        return responseStatus === 200 || responseStatus === 409;
    });
}
