var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { syncKiosk, triggerKiosk } from '../api/kioskControlApi';
export const triggerOnClick = (authContext, kioskTrigger) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    if ((_a = authContext === null || authContext === void 0 ? void 0 : authContext.kioskInfo) === null || _a === void 0 ? void 0 : _a.hasControl) {
        yield triggerKiosk((_b = authContext.accountInfo) === null || _b === void 0 ? void 0 : _b.token, authContext.kioskInfo.kioskId, kioskTrigger, authContext.handleReleaseKiosk);
    }
});
export const syncOnClick = (authContext, kioskSync) => __awaiter(void 0, void 0, void 0, function* () {
    var _c, _d;
    return ((_c = authContext === null || authContext === void 0 ? void 0 : authContext.kioskInfo) === null || _c === void 0 ? void 0 : _c.hasControl)
        ? syncKiosk((_d = authContext.accountInfo) === null || _d === void 0 ? void 0 : _d.token, authContext.kioskInfo.kioskId, kioskSync, authContext.handleReleaseKiosk)
        : true;
});
