import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import localization from '../../locale/localization';
import { useAuthentication, KIOSK_INACTIVE_STATE, } from '../../context/AuthenticationContextProvider';
/**
 * Inactivity modal used to alert users that they have been disconnected from the Kiosk
 */
function InactivityModal() {
    const [showModal, setShowModal] = useState(false);
    const { kioskInactiveState } = useAuthentication();
    const handleCloseModal = () => {
        setShowModal(false);
    };
    useEffect(() => {
        if (kioskInactiveState) {
            const modalShown = localStorage.getItem(KIOSK_INACTIVE_STATE);
            if (!modalShown) {
                setShowModal(true);
                localStorage.setItem(KIOSK_INACTIVE_STATE, 'true');
            }
            setShowModal(true);
        }
    }, [kioskInactiveState]);
    return (React.createElement(Modal, { show: showModal, onHide: handleCloseModal, size: "lg", centered: true },
        React.createElement(Modal.Header, { closeButton: true },
            React.createElement(Modal.Title, null, localization.inactivity.title)),
        React.createElement(Modal.Body, null,
            React.createElement("p", null, localization.inactivity.body))));
}
export default InactivityModal;
