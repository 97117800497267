import React from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import config from 'ConfigData';
import '../../../assets/css/components/CategoryTile.scss';
function CategoryTile({ category }) {
    let linkPath = `/categories/${category.id}`;
    if (category.name === 'Activities') {
        linkPath += '/quiz';
    }
    else if (category.tags && category.tags.length > 0 && !category.topLevel) {
        linkPath += `/hardware`;
    }
    else if (!category.children || category.children.length < 1) {
        linkPath += `/products`;
    }
    let tileImage;
    if (category.smallImage) {
        const imageUrl = `${config.retailInteractiveConsumerWebAppBaseUrl}${category.smallImage}`;
        tileImage = (React.createElement("img", { className: "d-flex category-image", src: imageUrl || null, alt: `Category ${category.name}` }));
    }
    else if (category.smallIcon) {
        tileImage = React.createElement("i", { className: `d-flex ${category.smallIcon} text-nin icon-size px-3` });
    }
    else {
        tileImage = React.createElement("div", { className: "d-flex category-image" });
    }
    return (React.createElement(Container, { className: "px-3" },
        React.createElement(Link, { className: "category-link", to: { pathname: linkPath } },
            React.createElement(Card, { className: "category-tile flex-row mx-0" },
                React.createElement("div", { className: "category-image-container col-4" }, tileImage),
                React.createElement("div", { className: "category-info-container col-8" },
                    React.createElement("h3", { className: "category-tile-title" }, category.name),
                    category.description && (React.createElement("p", { className: "category-tile-description" }, category.description)))))));
}
export default CategoryTile;
