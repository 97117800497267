import React from 'react';
import { useQuery } from '@apollo/client';
import '../../../assets/css/components/ProductTile.scss';
import { PRODUCT_BY_NSUID, PRODUCT_BY_SKU, PRODUCT_TYPE_SIMPLE } from '../../api/productGraph';
import ProductTileDisplay from './ProductTileDisplay';
function ProductTile({ nsuid, productId, selectedCategoryId }) {
    const { data } = useQuery(nsuid ? PRODUCT_BY_NSUID : PRODUCT_BY_SKU, {
        variables: {
            nsuid,
            sku: nsuid ? undefined : productId,
            locale: 'en_US',
        },
        context: { clientName: 'NintendoGraph' },
    });
    if (!data)
        return React.createElement("p", null, "Loading...");
    const nintendoProduct = data.products.find((product) => product.productType === PRODUCT_TYPE_SIMPLE);
    return nintendoProduct ? (React.createElement(ProductTileDisplay, { linkPath: nsuid
            ? `/categories/${selectedCategoryId}/products/${productId}/${nsuid}`
            : `/categories/${selectedCategoryId}/products/${productId}`, imageUrl: nintendoProduct.productImage ? nintendoProduct.productImage.url : null, productName: nintendoProduct.name })) : null;
}
ProductTile.defaultProps = {
    nsuid: '',
};
export default ProductTile;
