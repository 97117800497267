import React from 'react';
import { Navbar } from 'react-bootstrap';
import '../../../assets/css/components/BottomNavBar.scss';
import { useAuthentication } from '../../context/AuthenticationContextProvider';
/**
 * Bottom nav bar.
 * Includes a back button and a button for exiting the kiosk.
 *
 * Back button should take them back to the login screen, so they can choose to continue as guest or with NA login.
 * Exit kiosk button is enabled if they have control of a kiosk, and should relinquish control of the kiosk.
 */
function BottomNavBar() {
    const { handleReleaseKiosk, handleLogout } = useAuthentication();
    const navigationTabs = [
        {
            name: 'bottom-nav-exit',
            iconClass: 'fas fa-sign-out-alt',
            buttonText: 'Exit Kiosk',
            onClick: () => {
                // Logic here is the same whether or not they're in a Nintendo account...
                handleLogout();
                handleReleaseKiosk();
            },
        },
    ];
    return (React.createElement(Navbar, { className: "d-flex justify-content-evenly bottom-nav-bar", fixed: "bottom" }, navigationTabs.map((tab) => (React.createElement("button", { key: tab.name, className: "d-flex btn p-0 border-0 exit-kiosk-button", type: "button", onClick: () => {
            if (tab.onClick) {
                tab.onClick();
            }
        }, disabled: tab.disabled, "data-testid": tab.name },
        React.createElement("div", { className: "d-flex flex-column", key: tab.buttonText },
            React.createElement("div", { className: "d-flex justify-content-center" },
                React.createElement("i", { className: tab.iconClass })),
            tab.buttonText ? React.createElement("div", null, tab.buttonText) : null))))));
}
export default BottomNavBar;
