import React from 'react';
import { Route, Routes } from 'react-router-dom';
import CategorySelector from '../category/CategorySelector';
import ProductSelector from '../productselector/ProductSelector';
import AuthenticatedRoute from '../authentication/AuthenticatedRoute';
import SyncKioskRoute from '../SyncKioskRoute';
import LoadingDimmer from '../common/LoadingDimmer';
import { useCategories } from '../../context/CategoriesContextProvider';
import ProductDetails from '../productdetails/ProductDetails';
import Quiz from '../quiz/Quiz';
import { useAuthentication } from '../../context/AuthenticationContextProvider';
import { SyncType } from '../../types/longarmTypes';
import BottomNavBar from './BottomNavBar';
/**
 * The application main content, doesn't include the header.
 * This defines the primary react-router routes for navigating categories and products.
 */
function LongarmMain() {
    const categoriesContext = useCategories();
    const authenticationContext = useAuthentication();
    return (React.createElement("main", { id: "retail-interactive-content", "aria-live": "polite" }, categoriesContext.categories === null ||
        authenticationContext.capturingKiosk ||
        authenticationContext.handlingLogin ? (React.createElement(LoadingDimmer, null)) : (React.createElement(React.Fragment, null,
        React.createElement(AuthenticatedRoute, null,
            React.createElement(Routes, null,
                React.createElement(Route, { path: "/categories", element: React.createElement(SyncKioskRoute, { syncType: SyncType.LANDING },
                        React.createElement(CategorySelector, null)) }),
                React.createElement(Route, { path: "/categories/:categoryId", element: React.createElement(SyncKioskRoute, { syncType: SyncType.CATEGORY },
                        React.createElement(CategorySelector, null)) }),
                React.createElement(Route, { path: "/categories/:categoryId/products", element: React.createElement(SyncKioskRoute, { syncType: SyncType.CATEGORY },
                        React.createElement(ProductSelector, null)) }),
                React.createElement(Route, { path: "/categories/:categoryId/products/:productId/:nsuid", element: React.createElement(SyncKioskRoute, { syncType: SyncType.GAME },
                        React.createElement(ProductDetails, { syncType: SyncType.GAME })) }),
                React.createElement(Route, { path: "/categories/:categoryId/products/:accessoryId", element: React.createElement(SyncKioskRoute, { syncType: SyncType.HARDWARE },
                        React.createElement(ProductDetails, { syncType: SyncType.HARDWARE })) }),
                React.createElement(Route, { path: "/categories/:categoryId/hardware", element: React.createElement(SyncKioskRoute, { syncType: SyncType.HARDWARE },
                        React.createElement(ProductDetails, { syncType: SyncType.HARDWARE })) }),
                React.createElement(Route, { path: "/categories/activities/quiz", element: React.createElement(Quiz, null) }),
                React.createElement(Route, { path: "*", element: React.createElement(SyncKioskRoute, { syncType: SyncType.LANDING },
                        React.createElement(CategorySelector, null)) }))),
        React.createElement(BottomNavBar, null)))));
}
export default LongarmMain;
